import React, { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import { cabService } from "../functions/services";
import jwt from "jwt-decode";
import { history } from "./../functions/_helpers";
import { authHeader } from "./../functions/_helpers";

function UploadFilesComponent(props) {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [fileDetails, setFileDetails] = useState([]);
  const [cabMapping, setCabMapping] = useState([]);

  useEffect(() => {
    const fetchCabMappings = async () => {
      try {
        const response = await cabService.getCabsWithPermitNumbers();
        const cabMapping = response.reduce((map, cab) => {
          map[cab.permitNumber] = cab.cabId;
          return map;
        }, {});
        setCabMapping(cabMapping);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchCabMappings();
  }, []);
  /* Check access rights. Only admin (1) is allowed */
  let user_data =
    JSON.parse(localStorage.getItem("user")) == null
      ? []
      : JSON.parse(localStorage.getItem("user"));
  if (user_data === null || user_data.length === 0) {
    localStorage.clear();
    history.push("/login");
  }

  // Check Role
  const decodedToken = jwt(user_data.token);
  if (decodedToken == null || decodedToken == undefined) {
    localStorage.clear();
    history.push("/login");
  }

  const role = parseInt(decodedToken.role);
  if (role !== 1) {
    history.push("/");
  }
  const allowedFiles = [
    {
      name: "Pasientreiser",
      requiredCount: 2,
      type: "excel",
      expectedHeaders: ["Løyve", "Pasientnavn", "TotalSum", "Start_Dato"],
    },
    {
      name: "Cash",
      requiredCount: 1,
      type: "excel",
      expectedHeaders: ["Cash in hand", "License", "Start date", "Business"],
    },
    {
      name: "Skole",
      requiredCount: 1,
      type: "excel",
      expectedHeaders: [
        "Payment type",
        "License",
        "Business",
        "Start date",
        "Fare",
      ],
    },
    {
      name: "TT",
      requiredCount: 1,
      type: "excel",
      expectedHeaders: ["Turliste", "Taxam."],
    },
    {
      name: "Tesla",
      requiredCount: 1,
      type: "csv",
      expectedHeaders: [
        "ChargeStartDateTime",
        "ChargeStopDateTime",
        "Vin",
        "Total Exc. VAT",
        "Total Inc. VAT",
        "QuantityBase",
      ],
    },
    {
      name: "Visma",
      requiredCount: 1,
      type: "csv",
      expectedHeaders: [
        "Mva-kode",
        "Mva Navn",
        "Beskrivelse",
        "Beløp",
        "Valuta",
        "Beløp valuta",
      ],
    },
  ];

  const parsePrice = (value) => {
    if (typeof value === "string") {
      value = value.trim().replace(/\s+/g, "").replace(",", ".");
      return parseFloat(value) || 0;
    }
    return parseFloat(value);
  };

  const parseFile = (file, expectedHeaders) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          let jsonData;

          if (file.name.includes("Visma")) {
            const csvString = new TextDecoder("ISO-8859-1").decode(data);
            const separator = csvString.includes(";") ? ";" : ",";
            const rows = csvString
              .split("\n")
              .map((row) => row.split(separator))
              .filter((row) => row.some((cell) => cell.trim() !== "")); // Fjern tomme rader
            jsonData = rows;
          } else {
            const workbook = XLSX.read(data, { type: "array" });
            const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
            jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });
          }

          if (!jsonData || jsonData.length === 0) {
            throw new Error("Filen inneholder ingen data.");
          }

          const headers = jsonData[0].map((header) =>
            removeBOM(String(header).trim())
          );
          validateHeaders(headers, expectedHeaders);

          resolve(jsonData);
          console.log(jsonData);
        } catch (error) {
          reject(
            new Error(
              `Kunne ikke lese filen: ${file.name}. Feil: ${error.message}`
            )
          );
        }
      };

      reader.onerror = (error) =>
        reject(new Error(`FileReader-feil: ${error.message}`));

      reader.readAsArrayBuffer(file);
    });
  };

  const removeBOM = (header) => {
    return header.replace(/^\uFEFF/, "").trim();
  };

  const validateHeaders = (headers, expectedHeaders) => {
    if (!headers || headers.length === 0) {
      throw new Error("Filen inneholder ingen gyldige kolonneoverskrifter.");
    }

    const normalizedHeaders = headers.map((header) => header.toLowerCase());

    const missingHeaders = expectedHeaders.filter(
      (header) => !normalizedHeaders.includes(header.toLowerCase())
    );

    if (missingHeaders.length > 0) {
      throw new Error(`Manglende kolonner: ${missingHeaders.join(", ")}`);
    }
  };

  const extractRelevantData = (fileType, row, headers) => {
    const mappedRow = {};
    headers.forEach((header, index) => {
      mappedRow[header] = row[index];
    });

    const normalizedLicense = mappedRow["License"]
      ? normalizePermitNumber(mappedRow["License"])
      : null;
    const cabId =
      cabMapping[normalizedLicense] || cabMapping[mappedRow["License"]] || 1;

    const value = parsePrice(
      mappedRow["Beløp"] ||
        mappedRow["TotalSum"] ||
        mappedRow["Cash in hand"] ||
        mappedRow["Taxam."] ||
        mappedRow["Fare"] ||
        mappedRow["Total Inc. VAT"]
    );

    return {
      CreatedDateTime: new Date().toISOString(),
      CabID: cabId,
      Price: value,
      TripType: 1,
    };
  };

  const normalizePermitNumber = (permitNumber) => {
    if (!permitNumber) return permitNumber;
    permitNumber = permitNumber.toUpperCase().replace(/\s+/g, "");

    const løpeNummer = "420300";
    const forenkletLøyveRegexPattern = /^(AG\d{1,4})$/;
    const løyveRegexPattern = /^[A-Z]{2}\d{10}$|^[A-Z]-\d{8}$/;
    const løyveMedKRegexPattern = /^(K\d{1,3}|K-\d{1,3})$/;

    if (permitNumber.match(løyveRegexPattern)) return permitNumber;
    if (permitNumber.match(forenkletLøyveRegexPattern)) {
      const fylkePrefix = permitNumber.substring(0, 2);
      const identifikasjonsNummer = parseInt(permitNumber.substring(2), 10);
      const formattertNummer = identifikasjonsNummer
        .toString()
        .padStart(4, "0");
      return `${fylkePrefix}${løpeNummer}${formattertNummer}`;
    }
    if (permitNumber.match(løyveMedKRegexPattern)) {
      permitNumber = permitNumber.replace("-", "");
      const identifikasjonsNummer = parseInt(permitNumber.substring(1), 10);
      return `K-10030${identifikasjonsNummer.toString().padStart(3, "0")}`;
    }
    return permitNumber;
  };

  const handleFileUpload = async (e) => {
    const files = Array.from(e.target.files);
    const newUploadedFiles = [];
    const newErrorMessages = [];
    const newFileDetails = [];

    for (const file of files) {
      const fileTypeInfo = allowedFiles.find((rule) =>
        file.name.toLowerCase().includes(rule.name.toLowerCase())
      );

      if (!fileTypeInfo) {
        newErrorMessages.push(`Ukjent filtype: ${file.name}`);
        continue;
      }

      try {
        const fileData = await parseFile(file, fileTypeInfo.expectedHeaders);
        const headers = fileData[0];
        const rows = fileData.slice(1);

        const relevantData = rows.map((row) =>
          extractRelevantData(fileTypeInfo.name, row, headers)
        );
        console.log(relevantData);

        newFileDetails.push({
          name: file.name,
          type: fileTypeInfo.name,
          data: relevantData,
        });
        newUploadedFiles.push(file);
      } catch (error) {
        newErrorMessages.push(
          `Feil ved behandling av filen "${file.name}": ${error.message}`
        );
      }
    }

    setUploadedFiles(newUploadedFiles);
    setErrorMessages(newErrorMessages);
    setFileDetails(newFileDetails);
  };
  const uploadFiles = async () => {
    if (errorMessages.length > 0) {
      alert("Feil under opplasting. Vennligst sjekk feilene og prøv igjen.");
      return;
    }

    try {
      const trips = fileDetails
        .filter((file) => file.type !== "Visma" && file.type !== "Tesla")
        .flatMap((file) => file.data);

      const expenses = fileDetails
        .filter((file) => file.type === "Visma" || file.type === "Tesla")
        .flatMap((file) => file.data);

      if (trips.length === 0 && expenses.length === 0) {
        alert("Ingen data å laste opp.");
        return;
      }

      if (trips.length > 0) {
        const batchSize = 1000;
        const totalBatches = Math.ceil(trips.length / batchSize);

        for (let i = 0; i < totalBatches; i++) {
          const batch = trips.slice(i * batchSize, (i + 1) * batchSize);

          const tripResponse = await fetch(
            "http://localhost:5101/api/rig/uploadTrips",
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(batch),
            }
          );

          if (!tripResponse.ok) {
            const errorText = await tripResponse.text();
            throw new Error(
              `Feil ved opplasting av batch ${i + 1}: ${errorText}`
            );
          }
        }
      }
      /*000000
      if (expenses.length > 0) {
        const expenseResponse = await fetch(
          process.env.REACT_APP_API_URL + "/api/rig/uploadExpenses",
          {
            method: "POST",
            headers: authHeader(),
            body: JSON.stringify(expenses),
          }
        );

        if (!expenseResponse.ok) {
          const errorText = await expenseResponse.text();
          throw new Error(`Feil ved opplasting av expenses: ${errorText}`);
        }
      }
*/
      alert("Filer lastet opp og data importert!");
      setUploadedFiles([]);
      setFileDetails([]);
    } catch (error) {
      alert(`En feil oppstod: ${error.message}`);
    }
  };
  const formatNumberWithThousandsSeparator = (number) => {
    return new Intl.NumberFormat("no-NO", {
      style: "currency",
      currency: "NOK",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const getUploadProgress = () => {
    const totalRequiredFiles = allowedFiles.reduce(
      (acc, fileRule) => acc + fileRule.requiredCount,
      0
    );
    const totalUploadedFiles = uploadedFiles.length;
    return (totalUploadedFiles / totalRequiredFiles) * 100;
  };
  const calculateSummary = () => {
    const summary = {};

    fileDetails.forEach((file) => {
      file.data.forEach((entry) => {
        if (summary[entry.TripType]) {
          summary[entry.TripType] += parseFloat(entry.Price || 0);
        } else {
          summary[entry.TripType] = parseFloat(entry.Price || 0);
        }
      });
    });

    return Object.entries(summary).map(([tripType, totalPrice]) => ({
      tripType,
      totalPrice: Math.round(totalPrice),
    }));
  };
  return (
    <div className="file-upload-component">
      <h2>Last opp filer</h2>
      <input
        type="file"
        multiple
        onChange={handleFileUpload}
        accept=".xls,.xlsx,.csv"
      />
      {errorMessages.length > 0 && (
        <div className="error-messages">
          {errorMessages.map((msg, index) => (
            <p key={index}>{msg}</p>
          ))}
        </div>
      )}
      <div className="progressbar-container mb-4">
        <div
          className="progressbar bg-green-500 h-4 rounded"
          style={{ width: `${getUploadProgress()}%` }}
        ></div>
        <p className="text-sm">{Math.floor(getUploadProgress())}% fullført</p>
      </div>
      <div className="validation-status mb-4">
        {allowedFiles.map((fileRule, index) => {
          const uploadedCount = fileDetails.filter(
            (fileDetail) => fileDetail.type === fileRule.name
          ).length;
          const isValid = uploadedCount === fileRule.requiredCount;
          return (
            <div key={index} className="flex items-center mb-2">
              <p className="mr-2">
                {fileRule.name} ({fileRule.requiredCount} filer kreves):
              </p>
              <span
                className={`inline-block w-6 h-6 ${
                  isValid ? "bg-green-500" : "bg-red-500"
                } rounded-full`}
              >
                {isValid ? "✓" : "✗"}
              </span>
            </div>
          );
        })}
      </div>
      <button
        onClick={uploadFiles}
        className={`bg-blue-500 text-white p-2 rounded mb-4 ${
          errorMessages.length > 0 ? "opacity-50 cursor-not-allowed" : ""
        }`}
        disabled={errorMessages.length > 0}
      >
        Last opp
      </button>
      <h3 className="text-lg font-bold mt-4">Oppsummering av valgte filer:</h3>
      <table className="w-full border-collapse border border-gray-400 mt-4">
        <thead>
          <tr>
            <th className="border border-gray-400 p-2">Filtype</th>
            <th className="border border-gray-400 p-2">Total Sum</th>
          </tr>
        </thead>
        <tbody>
          {calculateSummary().map((row, index) => (
            <tr key={index}>
              <td className="border border-gray-400 p-2">{row.tripType}</td>
              <td className="border border-gray-400 p-2">
                {formatNumberWithThousandsSeparator(row.totalPrice)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default UploadFilesComponent;
