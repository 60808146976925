import { authHeader, history } from '../_helpers';

export const cabService = {
  getCab,
  getCabsWithPermitNumbers,
  getCabs,
  getCabsByRegionId,
  putCab,
  postCab,
  sendMessage,
  deleteCab,
  getCabCategories,
  getCabTypes,
  resetBreak,
  getQr
}

function getCab(id) {
   const requestOptions = {
       method: 'GET',
       headers: authHeader()
   };
    return fetch(process.env.REACT_APP_API_URL + '/api/cabs/cabById/' + id, requestOptions).then(handleResponse, handleError);
 }
 function getCabsWithPermitNumbers() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
     return fetch(process.env.REACT_APP_API_URL + '/api/rig/GetVehicles', requestOptions).then(handleResponse, handleError);
  }
function getCabs(id) {
   const requestOptions = {
       method: 'GET',
       headers: authHeader()
   };
   if (id > 0) {
      return fetch(process.env.REACT_APP_API_URL + '/api/cabs/' + id, requestOptions).then(handleResponse, handleError);
   }
   else {
      return fetch(process.env.REACT_APP_API_URL + '/api/cabs/', requestOptions).then(handleResponse, handleError);
   }
} 

function getCabsByRegionId(regionId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/cabs/cabByRegionId/' + regionId, requestOptions).then(handleResponse, handleError);
    
 } 
  
 
function putCab(cab){
    const requestOptions = {
        method: 'PUT',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cab)
    };

    console.log(cab);
    return fetch(process.env.REACT_APP_API_URL + '/api/cabs/' + cab.cabId, requestOptions).then(handleResponse, handleError);
}


 function postCab(cab){
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(cab)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/cabs' , requestOptions).then(handleResponse, handleError);
 }

 function deleteCab(id){
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };
     return fetch(process.env.REACT_APP_API_URL + '/api/cabs/' + id, requestOptions).then(handleResponse, handleError);
 }

 function getCabCategories() {
   const requestOptions = {
       method: 'GET',
       headers: authHeader()
   };
    return fetch(process.env.REACT_APP_API_URL + '/api/cabCategories/', requestOptions).then(handleResponse, handleError);
 }

 function getCabTypes() {
   const requestOptions = {
       method: 'GET',
       headers: authHeader()
   };
    return fetch(process.env.REACT_APP_API_URL + '/api/carTypes/', requestOptions).then(handleResponse, handleError);
 }

 function resetBreak(id){
   const requestOptions = {
       method: 'POST',
       headers: {...authHeader(), 'Content-Type': 'application/json'},
       body: JSON.stringify(id)
   };

   return fetch(process.env.REACT_APP_API_URL + '/api/cabs/resetBreak' , requestOptions).then(handleResponse, handleError);
}

function getQr(policy) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
     return fetch(process.env.REACT_APP_API_URL + '/api/cabs/getQrCodeForDriverAppByPolicyType/' + policy, requestOptions).then(handleResponse, handleError);
  }
 
function sendMessage(message){
    const requestOptions = {
        method: 'POST',
        headers: {...authHeader(), 'Content-Type': 'application/json'},
        body: JSON.stringify(message)
    };

    return fetch(process.env.REACT_APP_API_URL + '/api/message/sendMessage' , requestOptions).then(handleResponse, handleError);
 }


 function handleResponse(response) {
   return new Promise((resolve, reject) => {
       if (response.ok) {
           // return json if it was returned in the response

           var contentType = response.headers.get("content-type");
           if (contentType && contentType.includes("application/json")) {
                response.json().then(json => resolve(json));
           } else {
                resolve(response);
           }
       } else {
           // return error message from response body
           response.text().then(text => reject(text));
           console.log(response);
           if(response.status === 401){
               localStorage.clear();
               history.push('/login');
           }
       }
   });
 }


 function handleError(error) {
   return Promise.reject(error && error.message);
 }