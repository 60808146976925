import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import trip_home_icon from "../../../assets/image/web-icons/home.png";
import "./../../../css/main.css";
import { history } from "./../../../functions/_helpers";
import {
  cabService,
  regionService,
  permitService,
} from "../../../functions/services";
import jwt from "jwt-decode";
import QRCode from "react-qr-code";
import { SemipolarLoading } from "react-loadingg";
import { Alert } from "../../alerts";

const QR_POLICY = {
  standard: { value: "standard" },
  prerelease: { value: "prerelease" },
  nibbler: { value: "nibbler" },
};

const CabEdit = () => {
  const { id } = useParams();

  const [submitted, setSubmitted] = useState(false);
  const [changes, setChanges] = useState(false);
  const [cabCategories, setCabCategories] = useState([]);
  const [regions, setRegions] = useState([]);
  const [cabTypes, setCabTypes] = useState([]);
  const [permits, setPermits] = useState([]);

  const [qrCode, setQrCode] = useState(null);
  const [qrDataRequestToServer, setQrDataRequestToServer] = useState({
    policy: QR_POLICY.standard.value,
  });
  const [qrCreating, setQrCreating] = useState(false);
  const [cab, setCab] = useState({
    cabGuid: "",
    permitNumber: "",
    permitId: 0,
    regionId: 0,
    licenseNumber: "",
    brand: "",
    model: "",
    year: 2021,
    cabCategoryId: 1,
    carTypeId: 1,
    registrationCode: "",
    deleted: false,
    bookingPermissionType: 0,
    permit: {
      permitNumber: "Velg løyvenummer",
    },
    operatingCategories: [],
    cabExpenses: 0,
    isOwnedByIntercab: false,
    vin: "",
  });

  const [errors, setErrors] = useState();

  let optionBookingsItems = [
    <option key="booking-0" value={0}>
      Alle typer
    </option>,
    <option key="booking-1" value={1}>
      Kun praiing
    </option>,
  ];

  /* Check access rights. Only admin (1) is allowed */
  let user_data =
    JSON.parse(localStorage.getItem("user")) == null
      ? []
      : JSON.parse(localStorage.getItem("user"));
  if (user_data === null || user_data.length === 0) {
    localStorage.clear();
    history.push("/login");
  }

  // Check Role
  const decodedToken = jwt(user_data.token);
  if (decodedToken == null || decodedToken == undefined) {
    localStorage.clear();
    history.push("/login");
  }

  const role = parseInt(decodedToken.role);
  if (role !== 1) {
    history.push("/");
  }

  useEffect(() => {
    // Load driver
    if (id > 0) {
      cabService.getCab(id).then(
        (response) => {
          setCab(response);
        },
        (error) => {
          console.log(error);
        }
      );
    }

    regionService.getRegions().then(
      (response) => {
        setRegions(response);
      },
      (error) => {
        console.log(error);
      }
    );

    permitService.getPermits().then(
      (response) => {
        setPermits(response);
      },
      (error) => {
        console.log(error);
      }
    );

    cabService.getCabCategories().then(
      (response) => {
        setCabCategories(response);
      },
      (error) => {
        console.log(error);
      }
    );

    cabService.getCabTypes().then(
      (response) => {
        setCabTypes(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setCab((cab) => ({ ...cab, [name]: value }));
    setChanges(true);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    cab.permitId = parseInt(cab.permitId);
    cab.regionId = parseInt(cab.regionId);
    cab.cabCategoryId = parseInt(cab.cabCategoryId);
    cab.carTypeId = parseInt(cab.carTypeId);
    cab.year = parseInt(cab.year);

    if (
      changes &&
      cab.permitId > 0 &&
      cab.regionId > 0 &&
      cab.cabCategoryId > 0 &&
      cab.carTypeId > 0
    ) {
      if (id > 0) {
        cabService.putCab(cab).then(
          (response) => {
            history.push("/cabs");
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        cabService.postCab(cab).then(
          (response) => {
            console.log("Post success");
            history.push("/cabs");
          },
          (error) => {
            console.log(error);
          }
        );
      }
    }
  }

  const handleBack = () => {
    history.push("/cabs");
  };

  const handleCabOperationTypeChange = (categoryId) => {
    var found = false;
    var newArray = cab.operatingCategories.map((cat) => {
      if (cat.cabCategoryId == categoryId) {
        found = true;
        cat.selected = !cat.selected;
      }
      return cat;
    });

    if (found) {
      setCab((cab) => ({ ...cab, operatingCategories: newArray }));
    } else {
      //Add
      var newCat = {
        operatingCategoryId: 0,
        cabId: id,
        cabCategoryId: categoryId,
        selected: true,
      };
      newArray.push(newCat);
      setCab((cab) => ({ ...cab, operatingCategories: newArray }));
    }
    setChanges(true);
  };

  const getCheckedOperatingType = (categoryId) => {
    var res = false;
    cab.operatingCategories.map((cat) => {
      if (cat.cabCategoryId == categoryId) {
        res = cat.selected;
      }
    });
    return res;
  };

  const isOperatingTypeDisabled = (seats) => {
    var cabSeats = 0;
    cabCategories.map((cat) => {
      if (cat.cabCategoryId == cab.cabCategoryId) {
        cabSeats = cat.seats;
      }
    });

    return seats > cabSeats;
  };

  // For cab categories
  let optionItems = null;
  if (cabCategories.length > 0) {
    optionItems = cabCategories.map((category) => (
      <option key={category.cabCategoryId} value={category.cabCategoryId}>
        {category.type} , {category.seats} passasjerer{" "}
      </option>
    ));
  }

  // for permits
  let optionPermitItems = null;
  if (permits.length > 0) {
    optionPermitItems = permits.map((permit) => (
      <option key={permit.permitId} value={permit.permitId}>
        {permit.permitNumber}
      </option>
    ));
  }

  // for regions
  let optionRegionItems = null;
  if (regions.length > 0) {
    optionRegionItems = regions.map((region) => (
      <option key={region.regionId} value={region.regionId}>
        {region.name}
      </option>
    ));
  }

  // for types
  let optionTypesItems = null;
  if (regions.length > 0) {
    optionTypesItems = cabTypes.map((type) => (
      <option key={type.carTypeId} value={type.carTypeId}>
        {type.type}
      </option>
    ));
  }

  // for cabOperatingCategories
  let cabOperatingCategories = null;
  if (cabCategories.length > 0) {
    cabOperatingCategories = cabCategories.map((category) => (
      <div key={category.cabCategoryId + "_2"}>
        <input
          type="checkbox"
          disabled={isOperatingTypeDisabled(category.seats)}
          name={category.cabCategoryId}
          checked={getCheckedOperatingType(category.cabCategoryId)}
          onChange={() => handleCabOperationTypeChange(category.cabCategoryId)}
        />
        {category.type} , {category.seats} passasjerer
      </div>
    ));
  }

  const getMenuText = function () {
    if (id == 0) {
      return "LEGG TIL";
    } else {
      return "ENDRE";
    }
  };

  const getHeading = function () {
    if (id == 0) {
      return "Ny bil";
    } else {
      return "Endre bil";
    }
  };

  const handleGetQr = function (e) {
    setQrCode(null);
    setErrors(null);

    if (!qrDataRequestToServer.policy) {
      return;
    }

    setQrCreating(true);

    cabService
      .getQr(qrDataRequestToServer.policy)
      .then(
        (response) => {
          console.log("Resp success");
          console.log(response);

          if (!response || response.status === 204) {
            setErrors("Ingenting ble returnert fra server");
            return;
          }

          response.json().then((json) => {
            let qrCode = json ? json : null;

            if (qrCode) {
              qrCode =
                typeof qrCode !== "string" ? JSON.stringify(qrCode) : qrCode;
            }

            setQrCode(qrCode);
          });
        },
        (error) => {
          console.log(error);
          const errorReturned = error ? error : "Det oppstod en server feil";
          setErrors(errorReturned);

          /*
        for test:
        const response = {
            "qrCode": "{\"android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME\":\"com.google.android.apps.work.clouddpc/.receivers.CloudDeviceAdminReceiver\",\"android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM\":\"I5YvS0O5hXY46mb01BlRjq4oJJGs2kuUcHvVkAPEXlg\",\"android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION\":\"https://play.google.com/managed/downloadManagingApp?identifier=setup\",\"android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE\":{\"com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN\":\"NZJDBDCTNQBYZEJPRNUA\"}}",
            "enrollmentLink": "https://enterprise.google.com/android/enroll?et=NZJDBDCTNQBYZEJPRNUA"
        }

        const qrCode = (response || {}).hasOwnProperty('qrCode')? response.qrCode: null;
        console.log('qrCode');
        console.log(qrCode);
        console.log('parsed');
        const parsed = JSON.parse(qrCode);
        console.log(parsed);
        console.log(parsed["android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE"]["com.google.android.apps.work.clouddpc.EXTRA_ENROLLMENT_TOKEN"]);
        console.log(JSON.stringify(parsed));
        

         setQrCode(JSON.stringify(parsed)); */
        }
      )
      .finally(() => {
        setQrCreating(false);
      });
  };

  const handleQrPolicyChanged = function (e) {
    setErrors(null);
    const policy = ((e || {}).target || {}).value || null;
    if (policy != null) {
      setQrDataRequestToServer({ ...qrDataRequestToServer, policy: policy });
    }
  };

  const closeError = () => {
    setErrors(null);
  };

  return (
    <>
      <div className="calc-container w-full lg:space-x-2 space-y-2 lg:space-y-0 mb-2 lg:mb-4 top-adjustment custom-border-main driver-container">
        <div className="flex w-full trip_header ml-2">
          <div className="col-8 col-md-6 col-lg-4 col-sm-4 col-xs-12 text-left">
            <img src={trip_home_icon} alt="home" />
            <span className="font-bold">
              <Link to="/" className="non-undeline-link">
                HJEM &nbsp; &nbsp; / &nbsp;{" "}
              </Link>
            </span>
            <span className="font-bold uppercase">
              {" "}
              <Link to="/cabs" className="non-undeline-link">
                BILER &nbsp; &nbsp; / &nbsp;
              </Link>
            </span>
            <span className="font-bold">
              {" "}
              {getMenuText()} &nbsp; &nbsp; / &nbsp;{" "}
            </span>
          </div>
          <div
            className="text-right inline-flex"
            style={{ position: "absolute", right: "30px" }}
          >
            <button
              type="button"
              className="add_btn"
              id="backBtn"
              onClick={() => handleBack()}
            >
              {" "}
              Tilbake{" "}
            </button>
          </div>
        </div>

        <div
          className="w-full trip_box ml-2 custom-add-item-border"
          style={{ width: "98.8%", overflow: "auto" }}
        >
          <form
            style={{ marginTop: "15px" }}
            name="form"
            className="form flex flex-wrap w-full card-border"
            onSubmit={handleSubmit}
          >
            <h4 className="trip_title font-bold mb-3"> {getHeading()} </h4>
            {errors ? (
              <div className="w-full mb-4">
                <Alert
                  color="bg-transparent border-red-500 text-red-500 alert-error"
                  onClose={closeError}
                  borderLeft
                  raised
                >
                  {errors}
                </Alert>
              </div>
            ) : null}
            <div className="flex w-full drivers">
              <div className="col-8 col-md-6 col-lg-4 col-sm-4 col-xs-12">
                <label className="font-bold block">
                  {" "}
                  Reg nr. (Kjennemerke){" "}
                </label>
                <input
                  type="text"
                  style={{ border: "1px solid #37708e" }}
                  name="licenseNumber"
                  value={cab.licenseNumber}
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.licenseNumber ? " is-invalid" : "")
                  }
                />
                <label className="font-bold block"> Kategori </label>
                <select
                  style={{ border: "1px solid #37708e" }}
                  value={cab.cabCategoryId}
                  name="cabCategoryId"
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.cabCategoryId ? " is-invalid" : "")
                  }
                >
                  {optionItems}
                </select>
                <label className="font-bold block"> Løyvenummer </label>
                <select
                  style={{ border: "1px solid #37708e" }}
                  value={cab.permitId}
                  name="permitId"
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.permitId ? " is-invalid" : "")
                  }
                >
                  <option value={cab.permitId}>
                    {cab.permit.permitNumber}
                  </option>
                  {optionPermitItems}
                </select>
                <label className="font-bold block">
                  Tillates å operere som:
                </label>
                <div className="cab_operating_type">
                  {cabOperatingCategories}
                </div>
              </div>
              <div className="col-8 col-md-6 col-lg-4 col-sm-4 col-xs-12">
                <label className="font-bold block"> Merke </label>
                <input
                  type="text"
                  name="brand"
                  style={{ border: "1px solid #37708e" }}
                  value={cab.brand}
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.brand ? " is-invalid" : "")
                  }
                />
                <label className="font-bold block"> Modell </label>
                <input
                  type="text"
                  name="model"
                  style={{ border: "1px solid #37708e" }}
                  value={cab.model}
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.model ? " is-invalid" : "")
                  }
                />
                <label className="font-bold block"> Årsmodell </label>
                <input
                  type="text"
                  name="year"
                  style={{ border: "1px solid #37708e" }}
                  value={cab.year}
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.year ? " is-invalid" : "")
                  }
                />
                <label className="font-bold block">
                  {" "}
                  Registreringskode (Intern registreringskode){" "}
                </label>
                <span className="cab-code block"> {cab.registrationCode}</span>
              </div>
              <div className="col-8 col-md-6 col-lg-4 col-sm-4 col-xs-12 profile_widget">
                <label className="font-bold block">Type</label>
                <select
                  name="carTypeId"
                  style={{ border: "1px solid #37708e" }}
                  value={cab.carTypeId}
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.carTypeId ? " is-invalid" : "")
                  }
                >
                  {optionTypesItems}
                </select>
                <label className="font-bold block">Kjøreområde</label>
                <select
                  style={{ border: "1px solid #37708e" }}
                  value={cab.regionId}
                  name="regionId"
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.regionId ? " is-invalid" : "")
                  }
                >
                  <option value={0}>Velg kjøreområde</option>
                  {optionRegionItems}
                </select>
                <label className="font-bold block">Tillatte bestillinger</label>
                <select
                  style={{ border: "1px solid #37708e" }}
                  value={cab.bookingPermissionType}
                  name="bookingPermissionType"
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.bookingPermissionType
                      ? " is-invalid"
                      : "")
                  }
                >
                  {optionBookingsItems}
                </select>
                <label className="font-bold block">Utgifter</label>
                <input
                  type="number"
                  name="cabExpenses"
                  style={{ border: "1px solid #37708e" }}
                  value={cab.cabExpenses}
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && cab.cabExpences === 0 ? " is-invalid" : "")
                  }
                />
                <label className="font-bold block">
                  VIN (Vehicle Identification Number)
                </label>
                <input
                  type="text"
                  name="vin"
                  style={{ border: "1px solid #37708e" }}
                  value={cab.vin || ""}
                  onChange={handleChange}
                  className={
                    "form-control" +
                    (submitted && !cab.vin ? " is-invalid" : "")
                  }
                />
                <label className="font-bold block">Eies av Intercab</label>
                <input
                  type="checkbox"
                  name="isOwnedByIntercab"
                  style={{ border: "1px solid #37708e" }}
                  checked={cab.isOwnedByIntercab}
                  onChange={(e) =>
                    setCab({ ...cab, isOwnedByIntercab: e.target.checked })
                  }
                />
                <div
                  className="driver_btns text-center mt-10"
                  style={{
                    marginRight: "0px",
                    height: "45px",
                    marginTop: "100px",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="sign_btn"
                    style={{ display: "inline" }}
                    onClick={(e) => handleBack(e)}
                  >
                    {" "}
                    Avbryt{" "}
                  </button>
                  <button
                    className="sign_btn"
                    style={{ display: "inline", marginLeft: "30px" }}
                    onClick={(e) => handleSubmit(e)}
                  >
                    {" "}
                    Lagre{" "}
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div className="qr-code-wrapper card-border">
            <div
              style={{ display: "flex", alignItems: "flex-end", width: "100%" }}
            >
              <div style={{ width: "70%" }}>
                <label className="font-bold block">Policy</label>
                <select
                  name="policyType"
                  style={{ border: "1px solid #37708e", width: "100%" }}
                  value={qrDataRequestToServer.policy}
                  onChange={handleQrPolicyChanged}
                  className={"form-control"}
                >
                  <option
                    key={QR_POLICY.standard.value}
                    value={QR_POLICY.standard.value}
                  >
                    Standard
                  </option>
                  <option
                    key={QR_POLICY.prerelease.value}
                    value={QR_POLICY.prerelease.value}
                  >
                    Pre-release
                  </option>
                  <option
                    key={QR_POLICY.nibbler.value}
                    value={QR_POLICY.nibbler.value}
                  >
                    Nibbler
                  </option>
                </select>
              </div>
              <div className="" style={{ alignItems: "center" }}>
                <button
                  disabled={qrCreating}
                  className="sign_btn"
                  style={{ display: "inline", marginLeft: "30px" }}
                  onClick={(e) => handleGetQr(e)}
                >
                  {qrCreating ? "...Henter" : "QR kode"}
                </button>
              </div>
            </div>
            {qrCreating ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <SemipolarLoading
                  size="small"
                  style={{ position: "relative" }}
                />
              </div>
            ) : null}
            {qrCode ? (
              <div
                style={{
                  background: "white",
                  padding: "16px",
                  width: "260px",
                  marginTop: "40px",
                }}
              >
                <QRCode
                  style={{ height: "auto", width: "100%" }}
                  value={qrCode}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default CabEdit;
